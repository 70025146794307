export const useNavbarStyle = (navbarColor: string) => {
  const isNavbarDark = computed(() => navbarColor === '#000033')

  const navbarStyle = computed(() => {
    return {
      '--navbar-bg-color': navbarColor,
      '--navbar-text-color': isNavbarDark.value
        ? 'var(--white)'
        : 'var(--cobalt-800)',
    }
  })

  const burgerButtonStyle = computed(() => {
    return {
      '--navbar-burger-color': isNavbarDark.value
        ? 'var(--white)'
        : 'var(--cobalt-600)',
    }
  })

  const langSwitcherStyle = computed(() => {
    return {
      '--lang-switcher-icon-color': isNavbarDark.value
        ? 'var(--white)'
        : 'var(--cobalt-800)',
    }
  })
  return {
    navbarStyle,
    burgerButtonStyle,
    langSwitcherStyle,
  }
}
